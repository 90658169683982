@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileCutOff: 500px;

#tab-estimate {

  max-width: 70rem;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
  }

  .estimates {

    display: flex;
    flex-wrap: wrap;
    &__estimate {
      flex: 1 1 25rem;
      background-color: darken($base-color,5);
      margin: 1rem;
      border-radius: .5rem;
      overflow: hidden;
      h2 {
        font-size: 1.2rem;
        font-weight: normal;
        margin-bottom: 0;
        color: darken($font-color, 5);
      }
      h3 {
        font-size: 0.8rem;
        font-weight: normal;
        color: darken($font-color, 45);
        margin-top: 0.2rem;
        margin-bottom: 0;
      }
      .negative-balance {
        padding: 1rem;
        text-align: left;
        background-color: darken($base-color,6);
        color: darken($font-color, 25);
        font-style: italic;
        font-size: .8rem;
      }
      .yield {
        padding: 1rem;
        background-color: darken($base-color,6);
        font-size: 1.5rem;
        h4 {
          font-size: 1rem;
          margin: 0;
          font-weight: normal;
          color: darken($font-color, 35);
        }
      }
      @media screen and (max-width: $mobileCutOff){
        margin: 1rem 0;
        border-radius: 0;
      }
      @media screen and (min-width: $mobileCutOff){
        .yield, .negative-balance {
          min-height: 3.3rem;
        }
        .negative-balance {
          padding: 1.6rem 1rem .4rem 1rem;
        }
      }
      &__numbers {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        &__number {
          flex: 1 1 10rem;
          h3 {
            font-size: 1rem;
            font-weight: normal;
            color: darken($font-color, 35);
            margin: .5rem 0;
          }
          .ether {
            font-size: 2rem;
            @media screen and (max-width: 350px){
              font-size: 1.8rem;
            }
          }
          .fiat {
            margin-top: .5rem;
            font-size: 1.3rem;
            color: darken($font-color, 35);
            @media screen and (max-width: 350px){
              font-size: 1.15rem;
            }
          }
        }
      }
    }

  }

}