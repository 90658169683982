@import "../../sass/colors.scss";

.counter {

  display: inline-block;
  padding: 0 .2em;
  position: relative;

  span {
    display: inline !important;
  }

  &.bold {
    font-weight: bold;
  }

  &.changing {
    transition: color .5s ease-in;
    border-radius: .2em;
    &.positive {
      color: $bright-color-green !important;
    }
    &.negative {
      color: $bright-color-pink !important;
    }
  }
  .counter-amount {
    position: relative;
    transition: color .5s ease-in;
  }
  &.stable {
    transition: color 2s ease-out;
  }

}