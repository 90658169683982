@import '../../../sass/colors';
@import '../../../sass/mixins';

$mobileCutOff: 600px;
$link-colors: $bright-color-teal,
$bright-color-purple,
$bright-color-orange,;
$link-colors-hover: $bright-color-teal-2,
$bright-color-purple-2,
$bright-color-orange-2,;

#liquidity-contract {

  max-width: 74rem;
  margin: 0 auto;

  .loader-horizontal {
    width: 20rem;
    margin: 1rem auto;
  }

  .buy {
    color: $bright-color-green;
  }

  .sale {
    color: $bright-color-pink-2;
  }

  h1 {
    font-size: 1.5rem;
  }

  .web3-missing {
    background-color: darken($base-color, 5);
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    text-align: left;
    color: darken($font-color, 20);
    margin: 1rem;
    h3 {
      margin-top: 0;
    }
    a {
      color: $bright-color-purple;
    }
    p {
      &:first-child { margin-top: 0; }
      &:last-child { margin-bottom: 0; }
    }
    .buy-contract-adr {
      display: flex;
      flex-wrap: wrap;
      &__qr {
        margin: 0 1rem 0 0;
        @media screen and (max-width: $mobileCutOff) {
          margin: 0 auto 1rem auto;
        }
      }
      &__adr {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(40rem)
      }
    }
    @media screen and (max-width: $mobileCutOff) {
      margin: 1rem 0;
    }
  }

  .error {
    text-align: left;
    background-color: darken($base-color, 5);
    margin: 1rem;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    word-break: break-word;
    h2 {
      color: $bright-color-pink-2;
      margin: 0 0 .5rem 0;
    }
  }

  .alert {
    text-align: left;
    background-color: darken($base-color, 5);
    margin: 1rem;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    h2 {
      color: $bright-color-orange;
      margin: 0 0 .5rem 0;
    }
  }


  .transactions {
    text-align: left;
    background-color: darken($base-color, 5);
    margin: 1rem;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    h2 {
      color: lighten($base-color,35);
      margin: 0;
    }
    &__transaction {
      margin-top: .5rem;
      button {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $font-color;
        background-color: transparent;
        border: 0;
        font-size: 1.2rem;
        cursor: pointer;
        max-width: 75vw;
        &:hover {
          color: lighten($base-color,65);
        }
      }
    }
  }

  .rates {

    display: flex;
    flex-wrap: wrap;

    &__rate {
      flex: 1 1 15rem;
      background-color: darken($base-color, 5);
      margin: 1rem;
      border-radius: 1rem;
      padding: 1.5rem 2rem 2rem 2rem;
      text-align: left;
      max-width: calc(100vw - 6rem);
      overflow: hidden;
      @media screen and (max-width: $mobileCutOff) {
        padding: 0 1rem 1rem 1rem;
      }

      h2 {
        margin: 0 0 1rem 0;
      }

      .amount {
        color: darken($font-color, 25);
        margin-bottom: .5rem;

        & > div {
          margin-bottom: .5rem;
        }

        input {
          background: darken($bright-color-teal, 10);
          border: 0;
          padding: .5rem 1rem;
          border-radius: .5rem;
          margin: .5rem;
          color: $font-color;
          font-size: 1.35rem;
          width: 8rem;
          vertical-align: text-bottom;
        }

        button {
          display: inline-block;
          position: relative;
          border: 0;
          padding: .5rem 1rem;
          border-radius: .5rem;
          margin: .5rem;
          color: darken($font-color,20);
          font-size: 1.35rem;
          vertical-align: text-bottom;
          cursor: pointer;
          background-color: lighten($base-color, 5);
          z-index: 10;
          &.unlock {
            position: relative;
            margin-left: -1.5rem;
            padding-left: 1.8rem;
            z-index: 9;
          }

          &:hover:not(:disabled) {
            background-color: lighten($bright-color-green, 5);
            box-shadow: 0 0 1rem $bright-color-green;
            color: $font-color;
          }

          &:disabled {
            background-color: #ddd;
            color: #aaa;
          }
        }
      }

      .payout, .rate, .balance {
        color: darken($font-color, 35);
        margin-bottom: .5rem;
        .counter {
          display: block;
          font-size: 1.8rem;
          color: $font-color;

          &-postfix {
            color: darken($font-color, 35);
          }
        }
      }

      .how-to {
        color: darken($font-color, 35);

        h3 {
          color: $font-color;
          margin: 1.5rem 0 .5rem 0;
        }

        &__address {
          color: $font-color;
          margin-top: .5rem;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        a {
          color: $bright-color-purple;

          &:hover {
            color: $bright-color-purple-2;
          }
        }

        .mdi-icon {
          margin: 0 .5rem;
          vertical-align: -.325rem;
        }
      }
    }
  }

  .more-info {
    margin: 1rem 0;
    color: darken($font-color, 35);
    @media screen and (max-width: $mobileCutOff) {
      margin-top: 0;
    }

    a {
      display: block;
      margin-top: .5rem;
      color: $bright-color-purple;

      &:hover {
        color: $bright-color-purple-2;
      }
    }

    .mdi-icon {
      margin-right: .5rem;
      vertical-align: -.45rem;
    }
  }

  table {
    width: calc(100% - 4rem);
    margin: 0 2rem;
    border-collapse: collapse;
    @media screen and (max-width: $mobileCutOff) {
      width: calc(100% - 2rem);
      margin: 0 1rem;
    }

    tr {
      &:nth-child(even) {
        background-color: darken($base-color, 3.5);
      }

      td {
        padding: .5rem;
        color: darken($font-color, 30);

        &:nth-child(1), &:nth-child(2) {
          text-align: left;
        }

        &.number {
          text-align: right;
        }

        .mdi-icon {
          fill: darken($font-color, 25);
        }

        @media screen and (max-width: $mobileCutOff) {
          &:nth-child(1), &:nth-child(3), &:nth-child(8), .long {
            display: none;
          }
        }
        @media screen and (min-width: $mobileCutOff) {
          &:nth-child(2), .short {
            display: none;
          }
        }
        @for $i from 1 through length($link-colors) {
          &.user-#{$i} {
            a {
              color: $font-color;
              //color: #{nth($link-colors, $i)} !important;
              &:hover {
                color: $bright-color-purple;
                //color: lighten(nth($link-colors-hover, $i),0);
              }
            }
          }
        }
      }

      th {
        padding: .5rem;

        &:nth-child(1) {
          text-align: left;
        }

        &.number {
          text-align: right;
        }

        &.header {
          text-align: center;
          font-size: 1.3rem;
        }

        @media screen and (max-width: $mobileCutOff) {
          &:nth-child(2), &:nth-child(7) {
            display: none;
          }
          &:nth-child(1) {
            text-align: center;
          }
        }
      }
    }
  }
}