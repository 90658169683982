@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#create-transaction {

  h2 {
    margin-top: 0;
  }

  .loader {
    margin: 4rem 3rem;
    background-color: lighten($base-color, 70);
  }

  .description {
    font-size: .9rem;
  }

  .message-text {
    border-left: .3rem solid  darken($font-color, 30);
    padding-left: 1rem;
    padding-bottom: .1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  .confirmation {

    h4 {
    }
    &__hash {
      font-size: .9rem;
      margin-bottom: 1rem;
      a {
        color: darken($bright-color-teal,10);
      }
    }
    &__text {
      margin-bottom: 1rem;
    }
  }

  .tool-tip__text {
    color: $font-color;
  }

  form {
    .inputs {
      margin: 1rem 0;
      input {
        &[type="number"]{
          border-radius: .5em;
          text-align: center;
          width: 10rem;
          border: .05rem solid lighten($base-color,70);
          margin-right: 2rem;
          padding: .5rem;
          font-size: 1.3rem;
          &:focus {
            outline: none;
          }
        }
        &[type="range"]{
          -webkit-appearance: none;  /* Override default CSS styles */
          border-radius: .32em;
          text-align: center;
          width: 20em;
          margin-top: .6em;
          height: .5rem;
          background-color: lighten($base-color,75);
          outline: none;
          appearance: none;
          opacity: 1;
          padding: 0 !important;
          vertical-align: .3rem;
          &::-webkit-slider-thumb {
            -webkit-appearance: none; /* Override default look */
            appearance: none;
            width: .8rem;
            height: 2rem;
            background: $base-color; /* Green background */
            cursor: grab; /* Cursor on hover */
            border-radius: .25em;
          }
          &::-moz-range-thumb {
            width: 1.4em;
            height: 1.4em;
            background: $bright-color-orange; /* Green background */
            cursor: pointer; /* Cursor on hover */
            border-radius: .5em;
          }
        }
        &::placeholder {
          color: $font-color;
          font-weight: normal;
        }
      }
    }

    button {
      width: 100%;
      padding: 1rem 0;
      cursor: pointer;
      background-image: linear-gradient(to bottom, darken($base-color,0%) 0%, darken($base-color, 4%) 40%);
      border: 0;
      border-radius: .5rem;
      font-size: 1rem;
      margin-bottom: .5rem;
      margin-top: 1.5rem;
      color: lighten($base-color,70);
      .mdi-icon {
        vertical-align: -.4rem;
        margin-right: .5rem;
      }
      &:hover {
        background-image: linear-gradient(to bottom, darken($bright-color-purple,10%) 0%, darken($bright-color-purple-2, 10%) 40%);
        color: lighten($base-color,100);
      }
    }

    .contract {
      display: none;
      font-size: .8rem;
      margin-top: 1rem;
      h4 {
        margin: 0 .5rem 0 0;
        display: inline-block;
      }
    }

  }


}