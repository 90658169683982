@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileCutOff: 500px;

#tab-rolls {

  max-width: 55rem;
  padding: 1rem 0;
  margin: 0 auto;
  border-radius: 1rem;
  @media screen and (max-width: 500px) {
    border-radius: 0;
    margin: .5rem 0 0 0;
    padding: 0;
  }

  h2 {
    margin: 0;
    @media screen and (min-width: $mobileCutOff) {
      margin-bottom: 1rem;
    }
    .active-player {
      margin-bottom: 1rem;
      font-size: 1rem;
      color: darken($font-color, 50);
      font-weight: normal;
      a {
        margin-left: .5rem;
        //color: darken($font-color, 20);
        color: $bright-color-orange;
        .mdi-icon {
          margin-left: .5rem;
          fill: $base-color;
          background-color: $bright-color-purple;
          border-radius: 50%;
          vertical-align: -.1rem;
          @include md-icon-size(1.05rem);
        }
        &:hover {
          color: $bright-color-orange-2;
          .mdi-icon {
            background-color: $bright-color-purple-2;
          }
        }
      }
    }
  }

  .loader-horizontal {
    width: 20rem;
    margin: 1rem auto;
  }

  .rolls {
    @media screen and (min-width: 500px){
      margin-top: 1rem;
    }
  }

  #troll-box-marquee {
    margin: 0;
  }
}
