@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#tab-contracts {

  max-width: 55rem;
  margin: 0 auto;

  .contract {
    text-align: left;
    padding: 1rem;
    @media screen and (min-width: 500px){
      background-color: darken($base-color, 5%);
      margin-bottom: 1rem;
      border-radius: .5rem;
    }
    @media screen and (max-width: 500px){
      &:nth-child(even){
        background-color: darken($base-color, 2.5);
      }
    }

    h4 {
      margin: 0 0 .5rem 0;
      font-weight: normal;
    }

    .mdi-icon {
      vertical-align: -.5rem;
      margin-right: .5rem;
      fill: lighten($base-color,50);
    }

    &__adr {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      color: $bright-color-orange;
      max-width: calc(80vw);
      &.readable {
        max-width: 40vw;
      }
    }

    &__read {
      display: inline-block;
      margin-left: .5rem;
      vertical-align: middle;
      &:before {
        content: '( ';
        color: $font-color;
      }
      &:after {
        content: ' )';
        color: $font-color;
      }
    }

  }

  .old-contracts {
    a {
      color: lighten($base-color,30);
    }
  }

}