@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileCutOff: 500px;

.player {

  .icon_positive { fill: url(#gradient-green) $bright-color-green !important; }
  .icon_negative { fill: url(#gradient-pink) $bright-color-pink !important; }

  background-color: darken($base-color, 5);
  margin-bottom: 1rem;
  text-align: left;
  padding: .5rem 1rem 1.5rem .5rem;
  border-radius: 1.5rem 1rem 1rem 1rem;
  .tool-tip {
    display: inline-block;
  }
  &__moniker {
    font-size: 1.25rem;
    a {
      color: lighten($bright-color-purple,5);
      font-weight: normal;
      &:hover {
        color: lighten($bright-color-purple-2,5);
        .mdi-icon {
          fill: darken($font-color,20);
        }
      }
      @media screen and (max-width: 350px){
        font-size: 1.2rem;
        margin-left: 3rem;
        display: block;
      }
    }
    &__placement {
      display: inline-block;
      background-color: $base-color;
      height: 2rem;
      width: 2.2rem;
      text-align: center;
      border-radius: 50%;
      margin-right: 0.75rem;
      padding-top: .2rem;
    }
  }
  &__numbers {
    display: flex;
    flex-wrap: wrap;
    & > a {
      flex: 0 0 100%;
      order: 1;
      margin-top: .5rem;
      color: darken($font-color,50);
      margin-left: 1rem;
      .counter {
        color: darken($font-color,17.5);
      }
      .mdi-icon {
        vertical-align: -.3rem;
        margin-right: .25rem;
      }
      &.first {
        margin-top: .75rem;
        margin-left: .6rem;
        font-size: 2rem;
        order: 0;
        .counter {
          color: darken($font-color,0);
        }
        .mdi-icon {
          margin-right: 0;
          vertical-align: -.35rem;
          @include md-icon-size(2.3rem);
        }
      }
      &.first {
        &:hover {
          color: gray;
        }
      }
      &:not(.first){
        cursor: pointer;
        &:hover {
          color: lighten($bright-color-purple-2,5);
        }
        .counter {
          margin-right: .2rem;
        }
      }
    }
  }
  &__profit {
  }
  &__wagered {
    .mdi-icon {
      fill: url(#gradient-teal) $bright-color-teal;
    }
  }
  &__rolls {
    .mdi-icon {
      fill: url(#gradient-teal) $bright-color-teal;
    }
  }
  @media screen and (max-width: $mobileCutOff){
    background-color: darken($base-color, 10);
    margin-bottom: 0;
    border-radius: 0;
    padding-bottom: 2.5rem;
    &:nth-child(even){
      background-color: darken($base-color, 6);
    }
  }
}