@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#tab-dividends {
  max-width: 55rem;
  margin: 0 auto;

  .icon_positive { fill: url(#gradient-green) $bright-color-green !important; }
  .icon_negative { fill: url(#gradient-pink) $bright-color-pink !important; }

  h1 {
    font-size: 1.5rem;
  }

  .totals {

    background-color: darken($base-color, 5);
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: .5rem;

    h4 {
      margin: 0;
      text-align: left;
    }
    .data-points {
      background-color: darken($base-color, 6.5);
      text-align: left;
      padding: 1rem;
      border-radius: .5rem;
      margin-top: 1rem;
      &__data-point {
        font-size: 1.3rem;
        margin-bottom: .25rem;
        &__sub {
          font-size: .9rem;
          color: darken($font-color, 30);
        }
      }
    }
  }

}