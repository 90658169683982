@import '../../sass/colors.scss';

header {
  h1 {
    font-size: 2.5rem;
    margin: 1rem 0 .5rem 0;
    .version {
      font-size: 1.5rem;
      vertical-align: 1rem;
      font-weight: normal;
      margin-left: .5rem;
      color: darken($font-color, 20);
    }
  }
  h4 {
    margin: 0 0 1rem 0;
    font-weight: normal;
    color: darken($font-color, 30);
  }
  @media screen and (max-width: 500px){
    background-image: linear-gradient(to top, transparentize(darken($base-color, 5%), 1) 0%, transparentize(darken($base-color,15%), 0));
    background-color: darken($base-color, 5%);
    padding: .5rem;
    h1 {
      font-size: 2rem;
      margin: 0;
      .version {
        font-size: 1.2rem;
        vertical-align: .5rem;
      }
    }
    h4 {
      font-size: 0.8rem;
      margin: 0;
    }
  }
}