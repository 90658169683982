@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$fill-colors: $bright-color-pink, $bright-color-teal, $bright-color-purple, $bright-color-orange, $bright-color-green;
$fill-gradients:
    url(#gradient-pink) $bright-color-pink,
    url(#gradient-teal) $bright-color-teal,
    url(#gradient-purple) $bright-color-purple,
    url(#gradient-orange) $bright-color-orange,
    url(#gradient-green) $bright-color-green
;

$std-button-size: 4.5rem;
$smaller-button-size: 3.5rem;
$smallest-button-size: 3rem;

#nav-bar {
  position: relative;
  margin: 0 auto;

  nav {
    display: inline-block;
    position: relative;
  }

  @media screen and (max-width: 500px){
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 100;
    padding: 1rem 0;
    background-image: linear-gradient(to bottom, transparentize(darken($base-color,6%), 1) 0%, transparentize(darken($base-color,6%), 0) 40%);
    @media screen and (min-height: 800px){
      padding-bottom: 2rem;
    }
  }

  .nav-bar__button {
    position: relative;
    display: inline-block;
    margin: $std-button-size * .5 / 3.5;
    cursor: pointer;
    a.orb {
      display: inline-block;
      background-color: darken($base-color,7%);
      border-radius: 50%;
      width: $std-button-size;
      height: $std-button-size;
      &:hover, &:focus, &.active {
        background-color: darken($base-color,5%);
        outline: none;
        .mdi-icon {
          fill: #fff;
        }
      }
      @media screen and (max-width: 500px){
        background-color: lighten($base-color,0%);
      }
    }
    .mdi-icon {
      @include md-icon-size($std-button-size * 2 / 3.5);
      margin-top: $std-button-size * .75 / 3.5;
    }
    @for $i from 1 through length($fill-colors) {
      &:nth-child(#{$i}){
        .mdi-icon {
          fill: #{nth($fill-gradients, $i)};
        }
        a.orb:hover, a.orb.active, a.orb:focus {
          box-shadow: 0 0 1.5rem #{nth($fill-colors, $i)};
        }
      }
    }
    @media screen and (max-width: 500px){
      margin: $smaller-button-size * .5 / 3.5;
      a.orb {
        width: $smaller-button-size;
        height: $smaller-button-size;
        .mdi-icon {
          @include md-icon-size($smaller-button-size * 2 / 3.5);
          margin-top: $smaller-button-size * .75 / 3.5;
        }
      }
    }
    @media screen and (max-width: 400px){
      margin: $smallest-button-size * .5 / 3.5;
      a.orb {
        width: $smallest-button-size;
        height: $smallest-button-size;
        .mdi-icon {
          @include md-icon-size($smallest-button-size * 2 / 3.5);
          margin-top: $smallest-button-size * .75 / 3.5;
        }
      }
    }
  }

  #extended-menu {
    position: absolute;
    bottom: -5rem;
    right: 0;
    background-color: #fff;
    width: 17rem;
    border-radius: .5rem;
    z-index: 9;
    color: #888;
    text-align: left;
    &.active {

    }
    &.inactive {
      bottom: -3rem;
    }
    &__links {
      margin: 1rem;
      a {
        display: block;
      }
    }
  }

  .notification {
    position: absolute;
    top: -3rem;
    right: -2.5rem;
    @media screen and (max-width: 500px){
      top: -3.5rem;
      right: -1.5rem;
      a.orb {
        box-shadow: 0 0 1.5rem #111;
      }
    }
    @media screen and (max-width: 350px){
      top: -3.8rem;
      right: 0;
    }
    a.orb {
      width: $smallest-button-size;
      height: $smallest-button-size;
      transition: background-color .3s ease-out;
      .mdi-icon {
        @include md-icon-size($smallest-button-size * 2 / 3.5);
        margin-top: $smallest-button-size * .75 / 3.7;
        margin-left: .1rem;
      }
      &.unread {
        background-color: $font-color;
        .mdi-icon {
          fill: $bright-color-orange-2;
        }
      }
    }
  }

}