@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

footer {
  color: darken($font-color, 60%);
  font-size: .8rem;
  margin: 1rem 0;
  .currency-of-choice {
    display: inline-block;
    color: darken($font-color, 40);
    font-size: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    .mdi-icon {
      vertical-align: -.2rem;
      fill: $bright-color-purple-2;
      @include md-icon-size(1.1rem);
    }
    &:hover, &:focus {
      color: darken($font-color, 20);
      outline: none;
      .mdi-icon {
        fill: $bright-color-purple;
      }
    }
  }
  @media screen and (max-width: 500px){
    margin-top: 0;
    margin-bottom: 10rem;
    padding-top: 1rem;
    background-image: linear-gradient(to top, darken($base-color, 0%) 0%, darken($base-color, 5%));
  }
}