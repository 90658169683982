.transition-slide-enter {
  left: -100vw;
}
.transition-slide-enter.transition-slide-enter-active {
  left: 0;
}
.transition-slide-exit {
  left: 0;
}
.transition-slide-exit.transition-slide-exit-active {
  left: -100vw;
}


@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}