@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.dividend-payout-warning {
  position: relative;
  max-width: 63.1rem;
  margin: 0 auto 0 auto;
  z-index: 10;
  font-size: 1.3rem;

  a {
    display: block;
    padding: .75rem;
    background-color: #121419;
    color: $bright-color-yellow;
    text-shadow: 0 0 .7rem opacify($bright-color-orange, .2);
    @media screen and (min-width: 500px) {
      border-radius: .5rem;
    }

    .mdi-icon {
      vertical-align: -.35rem;
      margin-right: .5rem;
      fill: darken($bright-color-orange, 10);
    }

    &:hover {
      box-shadow: 0 0 1rem opacify($bright-color-yellow, .2);
      color: lighten($bright-color-yellow, 50);

      .mdi-icon {
        fill: lighten($bright-color-orange, 50);
      }
    }
  }
}