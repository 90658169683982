@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#player-stats {

  margin-bottom: 1rem;

  .data-points {

    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 500px) {
      background-color: darken($base-color, 5);
      border-radius: .5rem;
      padding: .5rem;
      margin-bottom: 1rem;
    }

    &__data-point {
      padding: .5rem;
      flex: 1 1 6rem;
      h4 {
        margin: 0;
        color: darken($font-color, 30);
        font-weight: normal;
        font-size: .8rem;
      }
      span {

      }
      .mdi-icon{
        vertical-align: -.2rem;
        @include md-icon-size(1.1rem);
      }
    }
  }

  .etherscan-link {
    @media screen and (max-width: 600px) {
      margin-top: .5rem;
    }
  }

}