@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileBreakPoint: 500px;

#stats-date-range {
  margin-bottom: 2rem;
  .from-date {
    display: inline-block;
    margin-right: 2rem;
    width: 5.7rem;
    text-align: right;
  }
  .to-date {
    display: inline-block;
    margin-left: 2rem;
    width: 5.7rem;
    text-align: left;
  }
  .rc-slider {
    display: inline-block;
    width: calc(100% - 7rem);
    max-width: 45rem;
    &-rail {
      background-color: darken($base-color, 5);
      @media screen and (max-width: $mobileBreakPoint) {
        background-color: darken($base-color, 25);
      }
    }
    &-step {
    }
    &-track {
      background-color: $bright-color-orange;
    }
    &-handle {
      background-color: darken($base-color, 15);
      border-color: $bright-color-orange-2;
      border-width: .2rem;
      &:focus {
        box-shadow: none;
      }
    }
  }
  @media screen and (max-width: $mobileBreakPoint) {
    .from-date, .to-date {
      display: none;
    }

  }
}