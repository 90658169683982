@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#tab-stats {

  max-width: 70rem;
  padding: 1rem 0;
  margin: 0 auto;

  .loader-horizontal {
    width: 20rem;
    margin: 1rem auto;
  }

}