@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#popup {

  position: fixed;
  left: 0;
  right: 0;
  width: calc(100vw - 2rem);
  max-width: 35rem;
  top: 0;
  margin: 1rem auto; /* Will not center vertically and won't work in IE6/7. */
  color: $base-color;
  text-align: left;
  z-index: 100;
  @media screen and (min-height: 1000px){
    top: 10rem;
  }

  &.active {
    opacity: 1;
    transition: opacity  .25s ease-out, top .25s ease-out, transform .25s ease-out;
    #popup__content {
      transform: scaleX(1) scaleY(1);
      transition: opacity  .25s ease-out, top .25s ease-out, transform .25s ease-out;
    }
  }

  &.inactive {
    opacity: 0;
    transition: opacity .25s ease-in, top .25s ease-in, transform .25s ease-in;
    pointer-events: none;
    #popup__content {
      transform: scaleX(.925) scaleY(.925);
      transition: opacity .25s ease-in, top .25s ease-in, transform .25s ease-in;
    }
  }

  &.no-content {
    background-color: #9f59ef !important;
    left: -100%;
    margin: 0;
  }

  &__modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparentize(#000000,.6);
  }

  &__content {
    position: relative;
    background-color: #fff;
    padding: 1rem;
    @media screen and (min-width: 650px){
      padding: 1rem 1.5rem;
    }
    border-radius: 1rem;
    min-height: 10rem;
    &__close {
      position: absolute;
      top: .3rem;
      right: .5rem;
      height: 2.3rem;
      width: 2.3rem;
      border: 0;
      border-radius: 50%;
      text-align: center;
      padding-top: .35rem;
      background-color: rgba(255,255,255,0.5);
      .mdi-icon {
        fill: lighten($base-color,30);
      }
      &:hover, &:focus {
        background-color: #d8d8d8;
        cursor: pointer;
        outline: none;
        .mdi-icon {
          fill: lighten($base-color,20);
        }
      }
    }

    h1, h2, h3, h4 {
      margin-top: 0;
    }

  }

}