@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#extended-menu {
  background-color: #fff;
  text-align: left;
  h2 {
    margin: 0 0 1rem 0;
    font-weight: normal;
  }
  &__links {
    a {
      display: block;
      margin-bottom: 1rem;
      .mdi-icon {
        vertical-align: -.4rem;
        margin-right: .5rem;
      }
      &:focus, &:hover, &.active {
        outline: none;
        color: darken($bright-color-teal,10);
      }
    }
  }
}