@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileCutOff: 500px;
$link-colors:
        $bright-color-pink,
        $bright-color-teal,
        $bright-color-purple,
        $bright-color-orange,
        $bright-color-green,
        $bright-color-pink,
        $bright-color-teal,
        $bright-color-purple,
        $bright-color-orange,
        $bright-color-green,
;
$link-colors-hover:
        $bright-color-pink-2,
        $bright-color-teal-2,
        $bright-color-purple-2,
        $bright-color-orange-2,
        $bright-color-green-2,
        $bright-color-pink-2,
        $bright-color-teal-2,
        $bright-color-purple-2,
        $bright-color-orange-2,
        $bright-color-green-2,
;

#tab-exchanges {

  max-width: 60rem;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
  }

  .exchange {
    background-color: darken($base-color, 5);
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    h2 {
      font-size: 1.2rem;
      margin: 0 0 1rem 0;
    }
    &__description {
      color: darken($font-color, 35);
      border-left: .3rem solid  darken($font-color, 80);
      padding-left: 1rem;
      padding-bottom: .1rem;
      b {
        color: darken($font-color, 20);
      }
    }
    &__url {
      margin-top: 1.5rem;
      .mdi-icon {
        vertical-align: -.35rem;
        margin-right: .5rem;
      }
    }

    @for $i from 1 through length($link-colors) {
      &:nth-child(#{$i+1}){
        a {
          color: #{nth($link-colors, $i)};
          &:hover {
            color: lighten(nth($link-colors-hover, $i),0);
          }
        }
      }
    }
  }

}