@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileBreakPoint: 500px;

#last-hour-summary {
  position: relative;
  h3 {
    margin: .5rem 0;
    color: darken($font-color, 50);
    font-weight: normal;
    font-size: 1.2rem;
    @media screen and (min-width: $mobileBreakPoint) {
      font-size: 1rem;
      position: absolute;
      top: -2.1rem;
      left: 1rem;
      background-color: darken($base-color, 5);
      padding: .25rem 2rem;
      border-radius: .5rem .5rem 0 0;
    }
  }
  .data-points {

    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: $mobileBreakPoint) {
      background-color: darken($base-color, 5);
      border-radius: .5rem;
      padding: .5rem;
      margin-bottom: .5rem;
    }

    &__data-point {
      padding: .5rem;
      flex: 1 1 6rem;
      h4 {
        margin: 0;
        color: darken($font-color, 30);
        font-weight: normal;
        font-size: .8rem;
      }
      span {

      }
      .mdi-icon{
        vertical-align: -.2rem;
        @include md-icon-size(1.1rem);
      }
      &.clickable {
        cursor: pointer;
        &:hover, &:focus {
          outline: none;
          color: $bright-color-purple;
          border-radius: .5rem;
          background-color: darken($base-color, 3.5);
          h4 {
            color: $bright-color-purple-2;
          }
        }
      }
    }
  }

}