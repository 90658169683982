.game-paused-warning {
  position: relative;
  max-width: 61.1rem;
  background-color: #dbd9cb;
  border: .2rem solid #b3a861;
  margin: 1rem auto;
  padding: .75rem;
  color: #121419;
  z-index: 5;
  font-size: 1.3rem;
  @media screen and (min-width: 500px) {
    border-radius: .5rem;
  }

  .mdi-icon {
    vertical-align: -.35rem;
    margin-right: .5rem;
    fill: rgba(97, 82, 6, 0.71);
  }
}