@import '../../sass/colors.scss';

.loader-circle {

  display: inline-block;
  animation: rotationAnimation linear .7s;
  animation-iteration-count: infinite;
  height: 3em;
  width: 3em;
  border-top: 0.25em solid $base-color;
  border-left: 0.25em solid $base-color;
  border-bottom: 0.25em solid $bright-color-purple;
  border-right: 0.25em solid $bright-color-purple-2;
  border-radius: 50%;
  vertical-align: middle;
}

@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}