@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileBreakPoint: 500px;

#last-hour {

  h2 {
    margin: 0 0 1rem 0;
    font-weight: normal;
  }

  .data-points {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .5rem;
    &__data-point {
      flex: 1 1 12rem;
      margin: .5rem;
      background-color: #f0f0f0;
      padding: 0 1rem;
      border-radius: .5rem;
      @media screen and (max-height: 650px) {
        &:nth-child(n+4){
          display: none;
        }
      }
      h4 {
        font-weight: normal;
        margin: .5rem 0;
        font-size: .75rem;
      }
      &__value {
        margin-bottom: .5rem;
        .counter {
          font-size: 1.8rem;
        }
        .user {
          margin: .6rem 0;
          display: block;
          .mdi-icon {
            margin-right: .25rem;
            vertical-align: -.25rem;
            @include md-icon-size(1.2rem);
          }
        }
      }
    }

  }

}