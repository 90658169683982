@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$animationDelay: 0.25s;
$rollHeight: 2.5rem;
$mobileCutOff: 600px;

#tab-rolls {

  .icon_win { fill: url(#gradient-green) $bright-color-green !important; }
  .icon_very-win { fill: url(#gradient-gold) $bright-color-yellow !important; }
  .icon_loss { fill: url(#gradient-pink) $bright-color-pink !important; }

  .roll {
    display: flex;
    text-align: left;
    align-items: center;
    background-color: darken($base-color, 5%);
    padding: 1rem;
    opacity: 1;
    height: $rollHeight;
    border-radius: .5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &__detail {
      flex: 1 1 2rem;
    }
    @media screen and (min-width: $mobileCutOff) {
      margin-bottom: 1rem;
    }
    @media screen and (max-width: $mobileCutOff) {
      border-radius: 0;
      margin-bottom: 0 !important;
      padding: .75rem .5rem;
      &:nth-last-child(even){
        background-color: darken($base-color, 0%);
      }
    }
    &:hover {
      background-color: darken($base-color, 3.75);
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 1rem darken($bright-color-purple-2,0);
      z-index: 1;
    }
    &.new {
      opacity: 0;
      height: 0;
      animation: fadein .5s normal forwards ease-in;
      max-height: $rollHeight;
      @for $i from 1 through 20 {
        &:nth-last-child(#{$i}) {
          animation-delay: $i * $animationDelay;
        }
      }
    }

    &__result {
      position: relative;
      vertical-align: middle;
      display: inline-block;
      width: 3rem;
      font-size: 1.75rem;
      text-align: center;
      font-weight: bold;
      color: darken($font-color, 30);
      transition: 1s ease-out;
      &__win {
        color: $bright-color-green;
      }
      &__very-win {
        color: $bright-color-yellow;
        text-shadow: 0 0 1rem opacify($bright-color-orange, .2);
      }
      &__loss {
        color: $bright-color-pink-2;
      }
      &__unresolved {
        font-weight: normal;
      }
      &__rolling {
        font-size: 1.5rem;
        font-weight: normal;
        &:before {
          content: '\2039';
          margin-right: .2rem;
          font-size: 1.25rem;
        }
      }

      .loader-circle {
        position: absolute;
        top: -.6rem;
        width: 3rem;
        height: 3rem;
        left: -.5rem;
        border-width: .175rem;
        @media screen and (min-width: 350px) {
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
        @media screen and (max-width: 600px) {
          top: -.7rem;
        }
      }

    }

    &__user {
      display: inline-block;
      flex-grow: 2;
      vertical-align: middle;
      text-align: center;
      white-space: nowrap;
      color: darken($font-color, 10);
      @media screen and (max-width: 500px) {
        &__full {
          display: none;
          position: absolute;
        }
        &__abr {
          width: 5em;
          display: inline-block;
        }
      }
      @media screen and (min-width: 500px) {
        &__full {
          max-width: 10rem;
          display: inline-block;
        }
        &__abr {
          display: none;
        }
      }
      .mdi-icon {
        @include md-icon-size(1.4rem);
        vertical-align: -.35rem;
        margin-right: .25rem;
        fill: url(#gradient-teal) $bright-color-teal;
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
    }

    &__chance {
      text-align: center;
      color: darken($font-color, 30);
      flex-basis: .5rem;
      .text {
        margin-left: .5rem;
      }
      @media screen and (max-width: $mobileCutOff) {
        .text {
          display: none;
        }
      }
      .mdi-icon {
        @include md-icon-size(1.4rem);
        vertical-align: -.35rem;
        margin-right: .25rem;
        fill: url(#gradient-orange) $bright-color-orange;
      }
    }

    &__time {
      text-align: center;
      color: darken($font-color, 10);
      margin-right: 1rem;
      flex-grow: 1.5;
      @media screen and (min-width: $mobileCutOff) {
        .short {
          display: none;
        }
      }
      @media screen and (max-width: $mobileCutOff) {
        .long, .mdi-icon {
          display: none;
        }
      }
      div {
        display: inline-block;
      }
      .mdi-icon {
        @include md-icon-size(1.4rem);
        vertical-align: -.35rem;
        margin-right: .45rem;
        fill: url(#gradient-purple) $bright-color-purple;
        @media screen and (max-width: 400px) {
          display: none;
        }
      }
    }

    &__stake {
      $font-sizes: 1.00rem 1.12rem 1.25rem 1.50rem 1.75rem 2.00rem 2.5rem;
      text-align: right;
      min-width: 6rem;
      flex-basis: .5rem;
      @each $size in $font-sizes {
        $i: index($font-sizes, $size);
        &.size-#{$i} {
          font-size: $size;
          transition: 1s ease-out;
          .mdi-icon{
            @include md-icon-size($size);
            vertical-align: $size / -8.5;
          }
        }
      }
      .mdi-icon {
        margin-right: .25rem;
      }
      @media screen and (max-width: $mobileCutOff) {
        padding-right: 1rem;
      }
      @media screen and (min-width: 850px) {
        margin-right: 1rem;
      }
    }

    &__loader {
      position: absolute;
      top: -1rem;
      left: 0;
      .loader {
        width: 100vw;
        &:after {
          background-image: linear-gradient(0.45turn, $bright-color-orange, $bright-color-orange-2);
          box-shadow: 0 0 10px $bright-color-orange;
        }
      }
    }

  }

}

@keyframes fadein {
  from {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    padding: 0 1rem;
    height: 0;
  }
  to {
    opacity: 1;
    height: $rollHeight;
    @media screen and (min-width: $mobileCutOff) {
      padding: 1rem;
      margin-bottom: 1rem;
    }
    @media screen and (max-width: $mobileCutOff) {
      padding: .75rem .5rem;
    }
  }
}