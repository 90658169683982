@import '../../sass/colors.scss';

.tool-tip {
  position: relative;
  @media (hover) and (min-width: 500px) {
    &:hover {
      .tool-tip__text {
        display: block;
      }
    }
  }
  &__text {
    display: none;
    position: absolute;
    bottom: -3rem;
    background-color: darken($base-color-transparent,60);
    padding: .5rem 1rem;
    border-radius: .5rem;
    white-space: nowrap;
    z-index: 10;
    left: 0;
    user-select: none;
    &.set-right {
      left: auto;
      right: 0;
    }
    &.set-center {
      left: 50%;
    }
  }
}