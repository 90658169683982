@import './colors';
@import './fonts.scss';
@import './animations';

body {
  margin: 0;
  padding: 0;
  background-color: $base-color;
}

body, input {
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a {
  text-decoration: none;
  color: lighten($base-color,30);
  &:hover {
    color: lighten($base-color,35);
  }
}

.no-select {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}