@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.roll-details {

  .data-header {
    margin-top: 1rem;
    font-size: .9rem;
    color: lighten($base-color, 30);
  }
  .data-values {
    margin-left: .5rem;
  }

  a {
    color: darken($bright-color-orange,3.5);
  }

  &__time {

    &__full {
      font-size: 1.4rem;
    }
    &__relative {
      .short { display: none; }
      .long { display: inline-block; }
    }

  }

  &__status {
    font-size: 2rem;
    .win {
      color: darken($bright-color-green,10);
    }
    .loss {
      color: $bright-color-pink;
    }
    .result-text {
      font-size: 1.5rem;
    }
  }

  &__text {
    margin: 2rem 1rem;
    .loader {
      margin-right: 2rem;
      height: 2rem;
      width: 2rem;
      border-top: 0.25em solid lighten($base-color, 75);
      border-left: 0.25em solid lighten($base-color, 75);
    }
  }

  &__hashes {
    margin-top: 1rem;
    text-align: center;
    font-size: .7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80vw;
  }

}