@import './colors.scss';

#App {

  color: $font-color;
  text-align: center;
  margin: 0 auto;

  main {
    position: relative;
  }

  @media screen and (max-width: 500px) {
    background-color: darken($base-color, 5%);
  }

}

