@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#notification {

  a {
    color: $bright-color-purple-2;
    &:hover {
      color: $bright-color-purple;
    }
  }

  @media screen and (max-width: 340px){
    font-size: .9rem;
  }

}