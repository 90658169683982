@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#tab-current {

  //transition: .5s ease-out;

  #troll-box-marquee {
    max-width: 62rem;
  }

  &__cards {
    max-width: 65rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .card {
    position: relative;
    flex: 1 1 20rem;
    margin: 1rem;
    border-radius: .5rem;
    background-color: darken($base-color, 5%);
    min-height: 4rem;
    @media screen and (max-width: 500px){
      margin: 0;
      border-radius: 0;
      min-height: 10rem;
      &:not(:first-child){
        overflow: hidden;
      }
      &:nth-child(even){
        background-color: darken($base-color, 2%);
        .card__icon-background {
          .mdi-icon {
            fill: darken($base-color, 8.5%);
          }
        }
      }
    }
    &__icon-background {
      position: absolute;
      color: $base-color;
      top: -1.5rem;
      left: -1.5rem;
      transform: scaleX(-1);
      opacity: .3;
      .mdi-icon { @include md-icon-size(10rem); }
    }
    h4 {
      position: relative;
      margin: 1rem;
      color: darken($font-color, 50%);
      font-weight: normal;
      .mdi-icon {
        vertical-align: -.15rem;
        margin-right: .5rem;
        @include md-icon-size(1rem);
      }
    }
    &__footer {
      background-color: darken($base-color, 4%);
      border-radius: 0 0 .5rem .5rem;
      padding: 0.5rem 1rem;
      margin-top: 1rem;
      font-style: italic;
      text-align: left;
      color: darken($font-color, 60%);
      &:before {
        content: '* ';
      }
    }
    .icon_positive { fill: url(#gradient-green) $bright-color-green !important; }
    .icon_negative { fill: url(#gradient-pink) $bright-color-pink !important; }



    &.house-profit {
      position: relative;
      h4 {
        margin: 1rem 0 0 0;
      }
      .house-profit {
        &__eth {
          position: relative;
          font-size: 4rem;
          margin-bottom: .25rem;
          .mdi-icon {
            vertical-align: -.25rem;
            @include md-icon-size(3rem);
          }
          .counter-amount__decimals {
            font-size: 3rem;
            vertical-align: .65rem;
            opacity: .8;
          }
        }
        &__currency {
          color: darken($font-color, 35%);
          font-size: 1.3rem;
          margin-bottom: 1.5rem;
        }
      }
      .loader {
        position: absolute;
        left: 1rem;
        top: 1rem;
        width: 2rem;
        height: 2rem;
        border-width: .2rem;
      }
    }



    &.stats-24h {
      .stats-24h {
        &__data-points {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin: 0 1rem 1rem 1rem;
          &__data-point {
            flex: 1 1 9rem;
            margin: .5rem .5rem .8rem .5rem;
            text-align: center;
            font-size: 1.4rem;
            .tool-tip__text {
              font-size: 1rem;
            }
            .mdi-icon {
              margin-right: 0.25rem;
              vertical-align: -0.25rem;
              fill: url(#gradient-teal) $bright-color-teal;
            }
          }
        }
      }
    }




    &.your-share {
      .your-share {
        &__data-points {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin: 0 1rem 0 1rem;
          &__data-point {
            flex: 1 1 9rem;
            margin: .5rem;
            text-align: center;
            .mdi-icon {
              margin-right: 0.25rem;
              vertical-align: -0.25rem;
              fill: url(#gradient-orange) $bright-color-orange;
            }
            &__main-value {
              font-size: 1.5rem;
              .mdi-icon {
                @include md-icon-size(1.75rem);
              }
            }
            &__sub-value {
              margin-top: .25rem;
              font-size: 1rem;
              color: darken($font-color, 40%);
              .mdi-icon {
                @include md-icon-size(1.2rem);
              }
            }
          }
        }
      }
    }



    &.your-stack {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .your-stack {
        &__data-points {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin: 0 1rem 0 1rem;
          flex-grow: 1;
          &__data-point {
            flex: 1 1 9rem;
            margin: .5rem;
            text-align: center;
            .mdi-icon {
              margin-right: 0.25rem;
              vertical-align: -0.25rem;
              fill: url(#gradient-purple) $bright-color-purple;
              &.edit_icon {
                fill: darken($font-color, 40%);
                margin: 0;
              }
            }
            &__main-value {
              font-size: 1.5rem;
              .mdi-icon {
                @include md-icon-size(1.75rem);
              }
            }
            &__sub-value {
              margin-top: .25rem;
              font-size: 1rem;
              color: darken($font-color, 40%);
              .mdi-icon {
                @include md-icon-size(1.2rem);
              }
              &.edit {
              }
            }
          }
          .dice-amount {
            cursor: pointer;
            .edit {
              color: $bright-color-purple-2;
              .mdi-icon {
                margin: 0 .25rem;
                fill: $bright-color-purple;
              }
            }
            &:hover, &:focus {
              outline: none;
              .edit {
                color: $font-color;
                .mdi-icon {
                  fill: lighten($bright-color-purple,10);
                }
              }
            }
          }
        }
        &__edit-amount {
          position: relative;
          padding-bottom: 2rem;
          &__text {
            color: darken($font-color, 40%);
            padding: 0 2rem;
            text-align: left;
            margin-bottom: 1rem;
          }
          input {
            padding: .6rem 1rem;
            border: 0;
            border-radius: 1rem 0 0 1rem;
            font-size: 1.25rem;
            width: 7rem;
            vertical-align: middle;
            z-index: 50;
            &:focus {
              outline: none;
            }
          }
          .button {
            border-radius: 0 1rem 1rem 0;
            display: inline-block;
            cursor: pointer;
            height: 3rem;
            padding: 0 .75rem 0 .75rem;
            vertical-align: middle;
            background-image: linear-gradient(to bottom, darken($base-color,0%) 0%, darken($base-color, 4%) 40%);
            &:hover, &:focus {
              background-image: linear-gradient(to bottom, darken($bright-color-purple,10%) 0%, darken($bright-color-purple-2, 10%) 40%);
              outline: none;
              //box-shadow: 0 0 1.5rem $bright-color-purple;
              .mdi-icon {
                fill: $base-color;
              }
            }
            .mdi-icon {
              @include md-icon-size(2.2rem);
              fill: $bright-color-purple;
              margin-top: .4rem;
            }
          }
        }
      }
      .card__icon-background {
        top: -1.25rem;
        left: -1.5rem;
      }
      .exchanges  {
        a {
          display: block;
          margin: .5rem;
          background-color: darken($base-color, 3%);
          padding: .5rem;
          border-radius: .5rem;
          color: darken($font-color,30);
          strong {
            color: $bright-color-orange;
            &.expensive {
              color: $bright-color-purple-2;
            }
          }
          .mdi-icon {
            fill: url(#gradient-orange) $bright-color-orange;
            vertical-align: -.2rem;
          }
          &:hover {
            background-color: darken($base-color, 2.25%);
            color: darken($font-color,5);
            .mdi-icon {
              fill: lighten($bright-color-orange,0);
            }
            strong {
              color: $bright-color-yellow;
              &.expensive {
                color: $bright-color-purple;
              }
            }
          }
        }
        span.frozen {
          display: block;
          margin: .5rem;
          padding: .5rem;
          background-color: darken($base-color, 3%);
        }
        .mdi-icon {
          vertical-align: -.25rem;
          margin-left: .35rem;
        }
      }
    }


    &.rates {
      .rates {
        &__data-points {
          position: relative;
          display: flex;
          font-size: 1.2rem;
          flex-wrap: wrap;
          margin: 0 1rem 1rem 1rem;
          &__data-point {
            flex: 1 1 9rem;
            margin: .5rem;
            text-align: center;
            .tool-tip__text {
              font-size: 1rem;
            }
            .mdi-icon {
              margin: 0 .5rem;
              vertical-align: -0.25rem;
              fill: url(#gradient-orange) $bright-color-orange;
            }
            &__main-value {
            }
            &__sub-value {
              font-size: 1rem;
              color: darken($font-color, 40%);
              margin-top: .25rem;
              .mdi-icon {
                @include md-icon-size(1.2rem);
              }
              &.edit {
                margin-top: .5rem;
              }
            }
          }
        }
      }
    }


  }

}