@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$fill-colors: $bright-color-purple, $bright-color-teal, $bright-color-green, $bright-color-pink, $bright-color-orange;

$mobileCutOff: 500px;

#tab-top-players {

  .periods {
    padding-bottom: 1rem;
    @media screen and (max-width: $mobileCutOff){
      background-image: linear-gradient(to top, darken($base-color, 10), darken($base-color, 5));
    }
    &__period {
      display: inline-block;
      padding: .5rem 1rem;
      margin: .5rem 1rem;
      background-color: darken($base-color, 5);
      border-radius: .5rem;
      cursor: pointer;
      @media screen and (max-width: $mobileCutOff){
        background-color: darken($base-color, 15);
      }
      @for $i from 1 through length($fill-colors) {
        &:nth-child(#{$i}){
          color: #{nth($fill-colors, $i)};
          &:hover, &:focus, &.selected {
            outline: none;
            box-shadow: 0 0 1.5rem #{nth($fill-colors, $i)};
            color: $font-color;
          }
        }
      }
    }

  }


  &.loading .periods {
    @media screen and (max-width: $mobileCutOff){
      background-image: none;
    }
  }
}