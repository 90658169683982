@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#tab-roll-box {

  max-width: 55rem;
  margin: 0 auto;
  background-color: darken($base-color, 5);
  border-radius: 1rem;
  padding-bottom: 1rem;
  .loader {
    width: 20rem;
    margin: 1rem auto;
  }
  h1 {
    font-size: 1.8rem;
    padding-top: 1rem;
  }
  .enable-ethereum-message {
    text-align: left;
    padding: 0 2rem;
  }
  .message-input {
    margin-bottom: 3rem;
    text-align: left;
    padding: 0 2rem;
    input {
      padding: .6rem 1rem;
      width: calc(100% - 2rem);
      margin: 0 auto;
      border: 0;
      border-radius: .5rem;
      font-size: 1.25rem;
      &:focus {
        outline: none;
      }
    }
    button {
      border-radius: .5rem;
      display: inline-block;
      cursor: pointer;
      padding: .5rem 1rem;
      vertical-align: middle;
      border: 0;
      color: #fff;
      font-size: 1.2rem;
      margin-top: 1rem;
      background-image: linear-gradient(to bottom, lighten($base-color,10%) 0%, lighten($base-color, 0%) 40%);
      &:hover, &:focus {
        background-image: linear-gradient(to bottom, darken($bright-color-purple,10%) 0%, darken($bright-color-purple-2, 10%) 40%);
        outline: none;
        .mdi-icon {
          fill: $base-color;
        }
      }
      .mdi-icon {
        @include md-icon-size(1.2rem);
        fill: $bright-color-purple;
        vertical-align: -.2rem;
        margin-right: .25rem;
      }
    }
  }
  .messages {

    &__message {
      margin: 2rem 2rem 3rem 2rem;
      text-align: left;
      &.pending {
        opacity: 0.5;
      }
      &__user {
        font-size: 1.3rem;
        a {
          color: $bright-color-purple;
          &:hover {
            color: $bright-color-purple-2;
          }
        }
      }
      &__time {
        color: darken($font-color, 35);
        margin-bottom: .5rem;
        margin-top: .25rem;
        font-size: .8rem;
        .long {
          display: inline-block;
        }
        .short {
          display: none;
        }
      }
      &__text {
        border-left: .3rem solid  darken($font-color, 80);
        padding-left: 1rem;
        padding-bottom: .1rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        word-break: break-word;
      }
    }
  }
}