@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileCutOff: 500px;

#tab-top-players {
  max-width: 55rem;
  margin: 0 auto;
  transition: .25s ease-in;
  h1 {
    margin: 1rem;
    font-size: 1.8rem;
  }
  .loader-horizontal {
    width: 20rem;
    margin: 1rem auto;
    max-width: 90vw;
  }

  .loader {
    margin-bottom: 2rem;
  }

}