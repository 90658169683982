@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileCutOff: 600px;

.dividend-quarter {

  background-color: darken($base-color, 5);
  margin-bottom: 2rem;
  border-radius: .5rem;
  padding-top: 1rem;
  overflow: hidden;
  h2 {
    text-align: left;
    margin: 0 2rem;
  }
  h3 {
    margin: 0 2rem 1rem 2rem;
    text-align: left;
    font-size: .9rem;
    font-weight: normal;
    color: darken($font-color, 30);
  }

  &__data-points {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $mobileCutOff){
      background-color: darken($base-color, 6.5);
      border-radius: .5rem;
    }
    &__data-point {
      flex: 1 1 7rem;
      background-color: darken($base-color, 6.5);
      padding: 1rem;
      @media screen and (max-width: $mobileCutOff){
        margin: 0;
      }
      h4 {
        margin: 0 0 .25rem 0;
        font-weight: normal;
      }
      &__value {
        font-size: 2rem;
        margin-bottom: .25rem;
        .mdi-icon {
          vertical-align: -.28rem;
          @include md-icon-size(2rem);
        }
        @media screen and (max-width: $mobileCutOff){
          font-size: 1.4rem;
          .mdi-icon {
            vertical-align: -.15rem;
            @include md-icon-size(1.4rem);
          }
        }
      }
      &__sub-value {
        font-size: 1.1rem;
        color: darken($font-color, 30);
        margin-bottom: .5rem;
        .mdi-icon {
          vertical-align: -.15rem;
          @include md-icon-size(1rem);
        }
        @media screen and (max-width: $mobileCutOff){
          font-size: 1rem;
        }
      }
    }
    @media screen and (max-width: $mobileCutOff){
      .counter-amount__decimals {
        display: none !important;
      }
    }
  }

  &__asterisk {
    text-align: left;
    color: darken($font-color, 30);
    padding: .5rem 1rem;
    font-size: .8rem;
    &:before {
      content: '* ';
    }
  }

}