@import '../../../sass/colors';
@import '../../../sass/mixins';

#transaction-status {

  text-align: center;
  h1 {
    margin-bottom: .5rem;
  }
  h4 {
    font-weight: normal;
  }
  .check-icon {
    margin: .5rem 0 1rem 0;
    fill: $bright-color-green-2;
    @include md-icon-size(5rem);
  }
  .loader {
    margin-bottom: 1.5rem;
    &.loader-circle {
      border-top: 0.25em solid #ccc;
      border-left: 0.25em solid #ccc;
    }
  }
  .hash {
    font-size: .8rem;
    margin-bottom: .5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    font-size: .8rem;
    svg {
      margin-left: .25rem;
      vertical-align: -.225rem;
      @include md-icon-size(1rem);
    }
  }

}