@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$mobileBreakPoint: 500px;

$line-colors:
rgb(230, 126, 34),
rgb(52, 152, 219),
rgb(22, 160, 133),
rgb(241, 196, 15),
rgb(231, 76, 60),
rgb(39, 174, 96),
rgb(26, 188, 156),
rgb(155, 89, 182),
rgba(255, 255, 255, .5),
rgba(255, 255, 255, .5),
;

#stats-options {

  .options {
    display: flex;
    flex-wrap: wrap;
    &__toggler {
      flex: 1 1 15rem;
      cursor: pointer;
      padding: .5rem 0;
      border-radius: 1rem;
      margin-bottom: .5rem;
      text-align: left;
      &__icon {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        margin-right: 1rem;
        .mdi-icon {
          margin-top: .7rem;
          fill: darken($font-color,40);
          @include md-icon-size(1.5rem);
        }
      }
      &__text {
        vertical-align: middle;
        color: darken($font-color,25);
      }
      &:hover, &.checked, &:focus {
        .options__toggler__text {
          color: $font-color;
        }
        .options__toggler__icon {
          background-color: darken($base-color, 5);
          .mdi-icon {
            fill: $font-color;
          }
        }
      }
      @for $i from 1 through length($line-colors) {
        &:nth-child(#{$i}).checked .options__toggler__icon {
          box-shadow: 0 0 1.75rem #{nth($line-colors, $i)};
          .mdi-icon {
            fill: $font-color;
          }
        }
      }
    }
    @media screen and (max-width: $mobileBreakPoint) {
      padding: 0 1rem;
      &__toggler {
        text-align: center;
        flex: 1 1 4rem;
        &__text {
          display: none;
        }
        &__icon {
          margin: 0;
        }
      }
    }
  }
  .mobile-help {
    @media screen and (min-width: $mobileBreakPoint) {
      display: none;
    }
    padding: 1.5rem;
    color: darken($font-color,30);
    text-align: left;
  }

}