@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.dividend-countdown {
  position: relative;
  max-width: 61.5rem;
  margin: 1rem auto 0 auto;
  background-color: #121419;
  padding: .75rem;
  z-index: 5;
  font-size: 1.6rem;
  @media screen and (min-width: 500px) {
    border-radius: .5rem;
  }
  @media screen and (max-width: 500px) {
    margin: 0 auto;
    padding-top: 0;
  }

  h6 {
    margin: 0 0 .25rem 0;
    font-weight: normal;
    color: gray;
  }

  .date-part {
    display: inline-block;
    margin: 0 .5rem;

    &__postfix {
      margin-left: .1rem;
      opacity: .75;
      font-size: 1.1rem
    }

    &.golden {
      .date-part__value {
        color: $bright-color-yellow;
        text-shadow: 0 0 .7rem opacify($bright-color-orange, .2);
      }

      .date-part__postfix {
        color: lighten($bright-color-orange, 25);
      }
    }

    &.red {
      .date-part__value {
        color: $bright-color-pink;
      }

      .date-part__postfix {
        color: lighten($bright-color-pink-2, 25);
      }
    }

    &.dark {
      opacity: .25;
    }
  }
}