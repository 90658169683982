@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.latest-troll {
  position: relative;
  max-width: 63.1rem;
  margin: 1rem auto 0 auto;
  display: flex;
  text-align: left;
  z-index: 5;
  @media screen and (max-width: 499px) {
    flex-wrap: wrap;
    margin: .25rem auto 0 auto;
  }
  &__name {
    margin-right: .25rem;
    padding: 1rem;
    color: darken($font-color, 15);
    @media screen and (min-width: 500px) {
      border-radius: .5rem;
    }
    @media screen and (max-width: 499px) {
      flex-basis: 100%;
      font-size: .8rem;
      padding: 0 1rem .6rem 1rem;
    }
    time {
      font-size: .8rem;
      color: gray;
      .short {
        display: none;
      }
      @media screen and (max-width: 499px) {
        display: inline-block;
        .long {
          margin-left: .5rem;
        }
      }
    }
  }
  &__message {
    background-color: darken($base-color, 5%);
    padding: .75rem 1rem 1rem 1rem;
    flex-grow: 1;
    position: relative;
    border-radius: .5rem;
    p {
      margin: 0;
      font-size: 1.4rem;
      word-break: break-word;
      @media screen and (max-width: 499px) {
        font-size: 1.2rem;
      }
    }
    a.reply {
      display: block;
      margin-top: .25rem;
      font-size: .8rem;
      color: darken($bright-color-purple,5);
      .mdi-icon {
        @include md-icon-size(1rem);
        vertical-align: -.175rem;
        margin-right: .25rem;
        margin-left: .25rem;
        fill: url(#gradient-purple) $bright-color-purple;
      }
      &:hover {
        color: #fff;
        .mdi-icon {
          fill: lighten($bright-color-purple,10);
        }
      }
    }

    &:before {
      // arrow
      content: '';
      position: absolute;
      top: .85rem;
      left: -.7rem;
      border-top: .75rem solid transparent;
      border-bottom: .75rem solid transparent;
      border-right: 1rem solid darken($base-color, 5%);
      @media screen and (max-width: 499px) {
        top: -.55rem;
        left: .85rem;
        border-right: 1rem solid darken($base-color, 0%);
      }
    }

    @media screen and (max-width: 499px) {
      padding: .5rem .75rem .75rem .75rem;
      background-color: darken($base-color, 0%);
      margin: 0 .5rem;
    }
  }
}