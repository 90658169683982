$base-color: #1D1F28;
$base-color-transparent: rgba(29,31,40,0.8);

$font-color: #fff;

$bright-color-pink: #FF4A7E;
$bright-color-pink-2: #ff4a4f;
$bright-color-teal: #29a5ef;
$bright-color-teal-2: #24d4ef;
$bright-color-purple: #C159EF;
$bright-color-purple-2: #9f59ef;
$bright-color-orange: #E8820E;
$bright-color-orange-2: #e8472e;
$bright-color-green: #1bc86b;
$bright-color-green-2: #3ac8b4;
$bright-color-yellow: #e8ce65;
$bright-color-yellow-2: #e89a5a;


#gradient-pink stop {
  &:nth-child(1){ stop-color: lighten($bright-color-pink,0); }
  &:nth-child(2){ stop-color: $bright-color-pink-2; }
}

#gradient-teal stop {
  &:nth-child(1){ stop-color: lighten($bright-color-teal-2,10); }
  &:nth-child(2){ stop-color: $bright-color-teal; }
}

#gradient-purple stop {
  &:nth-child(1){ stop-color: lighten($bright-color-purple,10); }
  &:nth-child(2){ stop-color: $bright-color-purple-2; }
}

#gradient-orange stop {
  &:nth-child(1){ stop-color: lighten($bright-color-orange,10); }
  &:nth-child(2){ stop-color: $bright-color-orange-2; }
}

#gradient-green stop {
  &:nth-child(1){ stop-color: $bright-color-green; }
  &:nth-child(2){ stop-color: $bright-color-green-2; }
}

#gradient-gold stop {
  &:nth-child(1){ stop-color: $bright-color-yellow; }
  &:nth-child(2){ stop-color: $bright-color-orange; }
}