@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

#currency {
  h2 {
    margin: 0;
    font-weight: normal;
  }
  .currency-list {
    padding: .5rem;
    margin-bottom: .5rem;
    &__currency {
      padding: .5rem;
      border-radius: .5rem;
      cursor: pointer;
      &.selected, &:focus, &:hover {
        outline: none;
        color: darken($bright-color-teal,20);
      }
      .mdi-icon {
        margin-right: .25rem;
        vertical-align: -.1rem;
        fill: $bright-color-purple-2;
        @include md-icon-size(1rem);
      }
    }
  }
}